<script lang="ts" setup>
import { BREAKDOWN_TYPE } from "~/constants";
import type { ITeam, IBreakdownType, IEvent } from "~/types";

const { t } = useI18n();
const toast = useToast();
const segment = useSegment();
const props = defineProps<{
  modelValue?: boolean;
  event: IEvent;
  team: ITeam;
  onInsufficientBalance?: () => void;
  onBreakdownSubmitSuccess?: () => void;
}>();
const emit = defineEmits(["update:modelValue"]);
const isOpen = useVModel(props, "modelValue", emit);

const basicAdvantages = Array.from(new Array(4), (val, index) => t(`pages.breakdowns.send_modal.basic_advantages.advantage${index + 1}`));
const advancedAdvantages = Array.from(new Array(4), (val, index) => t(`pages.breakdowns.send_modal.advanced_advantages.advantage${index + 1}`));
const selectedBreakdownType = ref<IBreakdownType>("basic");
const isInsufficientBalance = computed(() => props.team ? props.team[`${selectedBreakdownType.value}_assigned`] < 1 : false);
const { isLoading: isSubmitting, execute: sendForBreakdown } = useAsyncState(
  () => sendEventForBreakdown(props.event.id, selectedBreakdownType.value),
  null,
  { immediate: false, onError, onSuccess },
);

onMounted(() => {
  if (props.team && props.team.basic_assigned > 0) selectedBreakdownType.value = BREAKDOWN_TYPE.BASIC;
  else selectedBreakdownType.value = BREAKDOWN_TYPE.ADVANCED;

  segment.track("Send For Breakdown Intent");
});

function onSubmit() {
  if (isInsufficientBalance.value) {
    props.onInsufficientBalance && props.onInsufficientBalance();
    onClose();
  } else {
    sendForBreakdown();
  }
}

function onSuccess() {
  onClose();
  toast.success(t("pages.breakdowns.send_modal.sent_success_message"));
  props.onBreakdownSubmitSuccess && props.onBreakdownSubmitSuccess();
  trackSendForBreakdownAnalytic();
}

function onError(err: unknown) {
  let message = "";

  if (isH3Error(err) && isAtlitError(err) && err.data.errors[0].statusCode) {
    message = t(`error_code.breakdown/${err.data.errors[0].statusCode}`);
  } else {
    message = parseErrorMessage(err, { t });
  }

  toast.error(message);
  trackSendForBreakdownAnalytic(message);
}

function onClose() {
  segment.track("Cancel Send For Breakdown Intent");
  isOpen.value = false;
}

function trackSendForBreakdownAnalytic(errorMessage?: string) {
  segment.track("Send For Breakdown Clicked", {
    team_id: props.team.id,
    team_name: props.team.name,
    credit_type: selectedBreakdownType.value,
    event_id: props.event.id,
    sport_type: props.team.sport_type,
    game_type: props.event.game_info.type,
    ...(errorMessage && { be_response_errors: errorMessage }),
  });
}
</script>

<template>
  <UModal v-model="isOpen">
    <div class="p-4">
      <div class="mb-1 text-end">
        <UButton title="Close" variant="link-secondary" icon="close" class="h-5 w-5" @click="onClose()" />
      </div>

      <div class="flex flex-col gap-6 md:px-6 md:pb-6">
        <h1 class="text-black dark:text-white text-2xl font-bold" v-text="t('labels.send_for_breakdown')" />
        <p class="text-neutral-light-800 dark:text-white text-xs font-medium" v-text="t('pages.breakdowns.send_modal.description')" />

        <div class="flex gap-4">
          <BreakdownsSendBreakdownTypeCard
            v-model="selectedBreakdownType"
            :title="t('pages.breakdowns.basic_package')"
            :options="basicAdvantages"
            type="basic"
          />
          <BreakdownsSendBreakdownTypeCard
            v-model="selectedBreakdownType"
            :title="t('pages.breakdowns.advanced_package')"
            :options="advancedAdvantages"
            type="advanced"
          />
        </div>
        <UButtonGroupConfirmCancel
          :loading="isSubmitting"
          :confirm-label="t('pages.breakdowns.send_modal.title')"
          :on-cancel-click="onClose"
          :on-confirm-click="onSubmit"
        />
      </div>
    </div>
  </UModal>
</template>
