<script setup lang="ts">
import type { IEvent } from "~/types";

const props = defineProps<{
  event: IEvent;
}>();

const user = useUser();
const { t } = useI18n();
const toast = useToast();
const segment = useSegment();

function onClick() {
  segment.track("Vidswap Match Overview Opened", formatTrackEvent(props.event));

  return getMatchOverviewUrl(props.event.id)
    .then((url) => window.open(url))
    .catch(onError);
}

function onError(err: unknown) {
  toast.error(parseErrorMessage(err, { t }));
}
</script>

<template>
  <UListItem
    v-if="user && props.event.permissions.canOpenVS"
    icon="vs"
    :text="t('labels.match_overview')"
    :on-click="onClick"
  />
</template>
