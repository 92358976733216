<script lang="ts" setup>
import type { IBreakdownType } from "~/types";

interface SendBreakdownTypeCardProps {
  modelValue: string;
  title: string;
  options?: string[];
  type: IBreakdownType;
  linkUrl?: string;
  hasOptions?: boolean; // control showing options
}
const { t } = useI18n();
const props = defineProps<SendBreakdownTypeCardProps>();
const emit = defineEmits(["update:modelValue"]);
const innerValue = useVModel(props, "modelValue", emit);
const isActive = computed(() => props.type === innerValue.value);

function onClick() {
  innerValue.value = props.type;
}
</script>

<template>
  <div
    class="flex w-1/2 rounded cursor-pointer ease-in transition-all border border-neutral-light-200 dark:text-white"
    :class="[isActive && 'border-blue-500']"
    @click="onClick"
  >
    <button class="flex flex-col px-4 py-[18px] w-full gap-4">
      <div class="flex items-center justify-between w-full text-lg font-bold">
        {{ props.title }}
        <div
          class="flex items-center justify-center relative w-4 h-4 rounded-full border border-neutral-light-300"
          :class="[isActive && ['bg-blue-500 border-none']]"
        >
          <UIcon
            v-show="isActive"
            name="check"
            class="flex text-[9px] text-white"
          />
        </div>
      </div>
      <ul
        v-if="hasOptions"
        class="flex flex-col max-w-[160px] gap-2"
      >
        <div
          v-for="option in props.options"
          :key="option"
          class="flex"
        >
          <UIcon
            name="check"
            class="relative text-[9px] text-blue-500 top-[6px] pr-3"
          />
          <li class="relative inline-block text-sm font-medium text-left">
            {{ option }}
          </li>
        </div>
        <div class="flex">
          <a
            :href="props.linkUrl"
            class="text-blue-500 text-sm underline font-medium underline-offset-2 decoration-[1.5px]"
          >
            {{ t("pages.breakdowns.send_breakdown.learn_more") }}
          </a>
        </div>
      </ul>
    </button>
  </div>
</template>
